import React from "react"

const Iso2012 = () => {
    return (
        <>
            <div className="row">
                <div className="col">
                    <h2>ISO-8217:2012</h2>
                </div>
            </div>

            <div className="row ">
                <div className="col">
                    <h3>Distillate marine fuels</h3>
                    <div className="table-responsive">
                        <table className="table table-bordered table-sm ">
                            <thead className="">
                                <tr>
                                    <th scope="col" className="">
                                        Parameter
                                    </th>
                                    <th scope="col" className="text-center ">
                                        Unit
                                    </th>
                                    <th scope="col" className="text-center ">
                                        Test method
                                    </th>
                                    <th scope="col" className="text-center ">
                                        Limit
                                    </th>
                                    <th scope="col" className="text-center ">
                                        DMX
                                    </th>
                                    <th scope="col" className="text-center ">
                                        DMA
                                    </th>
                                    <th scope="col" className="text-center ">
                                        DMZ
                                    </th>
                                    <th scope="col" className="text-center ">
                                        DMB
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Density at 15 °C</th>
                                    <td className="text-center">
                                        kg/m<sup>3</sup>
                                    </td>
                                    <td className="text-center">ISO 3675/12185</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">890</td>
                                    <td className="text-center">890</td>
                                    <td className="text-center">900</td>
                                </tr>
                                <tr>
                                    <th scope="row" className="align-middle">
                                        Viscosity at 40 °C <sup>(a)</sup>
                                    </th>
                                    <td className="text-center align-middle">mm²/s</td>
                                    <td className="text-center align-middle">ISO 3104</td>
                                    <td className="text-center">
                                        Max
                                        <br />
                                        Min
                                    </td>
                                    <td className="text-center">
                                        5.5
                                        <br />
                                        1.4
                                    </td>
                                    <td className="text-center">
                                        6.0
                                        <br />
                                        2.0
                                    </td>
                                    <td className="text-center">
                                        6.0
                                        <br />
                                        3.0
                                    </td>
                                    <td className="text-center">
                                        11.0
                                        <br />
                                        2.0
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Micro Carbon Residue at 10%</th>
                                    <td className="text-center">% m/m</td>
                                    <td className="text-center">ISO 10370</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">0.3</td>
                                    <td className="text-center">0.3</td>
                                    <td className="text-center">0.3</td>
                                    <td className="text-center">-</td>
                                </tr>
                                <tr>
                                    <th scope="row">Micro Carbon Residue</th>
                                    <td className="text-center">% m/m</td>
                                    <td className="text-center">ISO 10370</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">0</td>
                                    <td className="text-center">0</td>
                                    <td className="text-center">0</td>
                                    <td className="text-center">0.3</td>
                                </tr>
                                <tr>
                                    <th scope="row">Water</th>
                                    <td className="text-center">% V/V</td>
                                    <td className="text-center">ISO 3733</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">
                                        0.3 <sup>(d)</sup>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        Sulphur <sup>(b)</sup>
                                    </th>
                                    <td className="text-center">% m/m</td>
                                    <td className="text-center">ISO 14596/8754</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">1.0</td>
                                    <td className="text-center">1.0</td>
                                    <td className="text-center">1.5</td>
                                    <td className="text-center">2.0</td>
                                </tr>
                                <tr>
                                    <th scope="row">Total Sediment by hot filtration</th>
                                    <td className="text-center">% m/m</td>
                                    <td className="text-center">ISO 10307-1</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">
                                        0.1 <sup>(d)</sup>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Ash</th>
                                    <td className="text-center">% m/m</td>
                                    <td className="text-center">ISO 6245</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">0.01</td>
                                    <td className="text-center">0.01</td>
                                    <td className="text-center">0.01</td>
                                    <td className="text-center">0.01</td>
                                </tr>
                                <tr>
                                    <th scope="row">Flash point</th>
                                    <td className="text-center">°C</td>
                                    <td className="text-center">ISO 2719</td>
                                    <td className="text-center">Min</td>
                                    <td className="text-center">43</td>
                                    <td className="text-center">60</td>
                                    <td className="text-center">60</td>
                                    <td className="text-center">60</td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        Pour point in Summer <sup>(c)</sup>
                                    </th>
                                    <td className="text-center">°C</td>
                                    <td className="text-center">ISO 3016</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">0</td>
                                    <td className="text-center">0</td>
                                    <td className="text-center">0</td>
                                    <td className="text-center">6</td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        Pour point in Winter <sup>(c)</sup>
                                    </th>
                                    <td className="text-center">°C</td>
                                    <td className="text-center">ISO 3016</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">-6</td>
                                    <td className="text-center">-6</td>
                                    <td className="text-center">-6</td>
                                    <td className="text-center">0</td>
                                </tr>
                                <tr>
                                    <th scope="row">Cloud point</th>
                                    <td className="text-center">°C</td>
                                    <td className="text-center">ISO 3015</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">-16</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                </tr>
                                <tr>
                                    <th scope="row">Calculated Cetane Index</th>
                                    <td className="text-center">-</td>
                                    <td className="text-center">ISO 4264</td>
                                    <td className="text-center">Min</td>
                                    <td className="text-center">45</td>
                                    <td className="text-center">40</td>
                                    <td className="text-center">40</td>
                                    <td className="text-center">35</td>
                                </tr>
                                <tr>
                                    <th scope="row">Acid number</th>
                                    <td className="text-center">mg KOH/g</td>
                                    <td className="text-center">ASTM D664</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">0.5</td>
                                    <td className="text-center">0.5</td>
                                    <td className="text-center">0.5</td>
                                    <td className="text-center">0.5</td>
                                </tr>
                                <tr>
                                    <th scope="row">Oxidation stability</th>
                                    <td className="text-center">g/m3</td>
                                    <td className="text-center">ISO 12205</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">25</td>
                                    <td className="text-center">25</td>
                                    <td className="text-center">25</td>
                                    <td className="text-center">
                                        25 <sup>(e)</sup>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        Lubricity, corrected wear scar diameter (wsd 1.4) at 60°C <sup>(g)</sup>
                                    </th>
                                    <td className="text-center">µm</td>
                                    <td className="text-center">ISO 12156-1</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">520</td>
                                    <td className="text-center">520</td>
                                    <td className="text-center">520</td>
                                    <td className="text-center">
                                        520 <sup>(g)</sup>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        Hydrogen sulphide <sup>(c)</sup>
                                    </th>
                                    <td className="text-center">mg/kg</td>
                                    <td className="text-center">IP 570</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">2.0</td>
                                    <td className="text-center">2.0</td>
                                    <td className="text-center">2.0</td>
                                    <td className="text-center">2.0</td>
                                </tr>
                                <tr>
                                    <th scope="row">Appearance</th>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td colSpan="3" className="text-center">
                                        Clear and bright (h)
                                    </td>
                                    <td className="text-center">
                                        <sup>(d)</sup> <sup>(e)</sup> <sup>(f)</sup>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col mb-3">
                    <div>
                        <sup>(a)</sup> 1 mm<sup>2</sup>/s = 1 cSt.
                    </div>
                    <div>
                        <sup>(b)</sup> Notwithstanding the limits given, the purchaser shall define the maximum sulfur content in
                        accordance with relevant statutory limitations.
                    </div>
                    <div>
                        <sup>(c)</sup> Purchasers should ensure that this pour point is suitable for the equipment on board, especially
                        if the ship operates in cold climates.
                    </div>
                    <div>
                        <sup>(d)</sup> If the sample is not clear and bright, the total sediment by hot filtration and water tests
                        shall be required.
                    </div>
                    <div>
                        <sup>(e)</sup> If the sample is not clear and bright, the test cannot be undertaken and hence the oxidation
                        stability limit shall not apply.
                    </div>
                    <div>
                        <sup>(f)</sup> If the sample is not clear and bright, the test cannot be undertaken and hence the lubricity
                        limit shall not apply.
                    </div>
                    <div>
                        <sup>(g)</sup> This requirement is applicable to fuels with a sulfur content below 500 mg/kg (0.050 mass %).
                    </div>
                    <div>
                        <sup>(h)</sup> If the sample is dyed and not transparent, then water test shall be required. The water content
                        shall not exceed 200 mg/kg (0.02% m/m).
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <h3>Residual marine fuels</h3>
                    <div className="table-responsive">
                        <table className="table table-bordered table-sm ">
                            <thead className="">
                                <tr>
                                    <th rowSpan="2" scope="col" className=" align-middle">
                                        Parameter
                                    </th>
                                    <th rowSpan="2" scope="col" className="text-center  align-middle">
                                        Unit
                                    </th>
                                    <th rowSpan="2" scope="col" className="text-center  align-middle">
                                        Test method
                                    </th>
                                    <th rowSpan="2" scope="col" className="text-center  align-middle">
                                        Limit
                                    </th>
                                    <th rowSpan="2" scope="col" className="text-center  align-middle">
                                        RMA10 <sup>(a)</sup>
                                    </th>
                                    <th rowSpan="2" scope="col" className="text-center  align-middle">
                                        RMB30
                                    </th>
                                    <th rowSpan="2" scope="col" className="text-center  align-middle">
                                        RMD80
                                    </th>
                                    <th rowSpan="2" scope="col" className="text-center  align-middle">
                                        RME180
                                    </th>
                                    <th colSpan="4" scope="col" className="text-center ">
                                        RMG
                                    </th>
                                    <th colSpan="3" scope="col" className="text-center ">
                                        RMK
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="col" className="text-center ">
                                        180
                                    </th>
                                    <th scope="col" className="text-center ">
                                        380
                                    </th>
                                    <th scope="col" className="text-center ">
                                        500
                                    </th>
                                    <th scope="col" className="text-center ">
                                        700
                                    </th>
                                    <th scope="col" className="text-center ">
                                        380
                                    </th>
                                    <th scope="col" className="text-center ">
                                        500
                                    </th>
                                    <th scope="col" className="text-center ">
                                        700
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Density at 15 °C</th>
                                    <td className="text-center">
                                        kg/m<sup>3</sup>
                                    </td>
                                    <td className="text-center">ISO 3675/12185</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">920</td>
                                    <td className="text-center">960</td>
                                    <td className="text-center">975</td>
                                    <td className="text-center">991</td>
                                    <td colSpan="4" className="text-center">
                                        991
                                    </td>
                                    <td colSpan="3" className="text-center">
                                        1010
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        Viscosity at 50 °C <sup>(b)</sup>
                                    </th>
                                    <td className="text-center">mm²/s</td>
                                    <td className="text-center">ISO 3104</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">10</td>
                                    <td className="text-center">30</td>
                                    <td className="text-center">80</td>
                                    <td className="text-center">180</td>
                                    <td className="text-center">180</td>
                                    <td className="text-center">380</td>
                                    <td className="text-center">500</td>
                                    <td className="text-center">700</td>
                                    <td className="text-center">380</td>
                                    <td className="text-center">500</td>
                                    <td className="text-center">700</td>
                                </tr>
                                <tr>
                                    <th scope="row">Water</th>
                                    <td className="text-center">% V/V</td>
                                    <td className="text-center">ISO 3733</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">0.3</td>
                                    <td colSpan="11" className="text-center">
                                        0.5
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Micro Carbon Residue</th>
                                    <td className="text-center">% m/m</td>
                                    <td className="text-center">ISO 10370</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">2.5</td>
                                    <td className="text-center">10.0</td>
                                    <td className="text-center">14.0</td>
                                    <td className="text-center">15.0</td>
                                    <td colSpan="4" className="text-center">
                                        18.0
                                    </td>
                                    <td colSpan="3" className="text-center">
                                        20.0
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        Sulphur <sup>(c)</sup>
                                    </th>
                                    <td className="text-center">% m/m</td>
                                    <td className="text-center">ISO 14596/8754</td>
                                    <td className="text-center">Max</td>
                                    <td colSpan="11" className="text-center">
                                        Statutory requirements
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Total Sediment, aged</th>
                                    <td className="text-center">% m/m</td>
                                    <td className="text-center">ISO 10307-2</td>
                                    <td className="text-center">Max</td>
                                    <td colSpan="11" className="text-center">
                                        0.1
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Sodium</th>
                                    <td className="text-center">mg/kg</td>
                                    <td className="text-center">IP 501/IP 470</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">50</td>
                                    <td colSpan="2" className="text-center">
                                        100
                                    </td>
                                    <td className="text-center">50</td>
                                    <td colSpan="7" className="text-center">
                                        100
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Ash</th>
                                    <td className="text-center">% m/m</td>
                                    <td className="text-center">ISO 6245</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">0.04</td>
                                    <td colSpan="3" className="text-center">
                                        0.07
                                    </td>
                                    <td colSpan="4" className="text-center">
                                        0.10
                                    </td>
                                    <td colSpan="3" className="text-center">
                                        0.15
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="align-middle">
                                        Vanadium
                                    </th>
                                    <td className="text-center align-middle">mg/kg</td>
                                    <td className="text-center align-middle">ISO 14597/IP 501/IP 470</td>
                                    <td className="text-center align-middle">Max</td>
                                    <td className="text-center align-middle">50</td>
                                    <td colSpan="3" className="text-center align-middle">
                                        150
                                    </td>
                                    <td colSpan="4" className="text-center align-middle">
                                        350
                                    </td>
                                    <td colSpan="3" className="text-center align-middle">
                                        450
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="align-middle">
                                        Aluminium + Silicon
                                    </th>
                                    <td className="text-center align-middle">mg/kg</td>
                                    <td className="text-center align-middle">ISO 10478/IP 501/IP 470</td>
                                    <td className="text-center align-middle">Max</td>
                                    <td className="text-center align-middle">25</td>
                                    <td colSpan="2" className="text-center align-middle">
                                        40
                                    </td>
                                    <td className="text-center align-middle">50</td>
                                    <td colSpan="7" className="text-center align-middle">
                                        60
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">CCAI</th>
                                    <td className="text-center">-</td>
                                    <td className="text-center">Calculated - 6.3 a)</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">850</td>
                                    <td colSpan="2" className="text-center">
                                        860
                                    </td>
                                    <td colSpan="8" className="text-center">
                                        870
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Flash point</th>
                                    <td className="text-center">°C</td>
                                    <td className="text-center">ISO 2719</td>
                                    <td className="text-center">Min</td>
                                    <td colSpan="11" className="text-center">
                                        60
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="align-middle">
                                        Pour point in Summer <sup>(e)</sup>
                                    </th>
                                    <td className="text-center align-middle">°C</td>
                                    <td className="text-center align-middle">ISO 3016</td>
                                    <td className="text-center align-middle">Max</td>
                                    <td className="text-center align-middle">6</td>
                                    <td colSpan="10" className="text-center align-middle">
                                        30
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="align-middle">
                                        Pour point in Winter <sup>(e)</sup>
                                    </th>
                                    <td className="text-center align-middle">°C</td>
                                    <td className="text-center align-middle">ISO 3016</td>
                                    <td className="text-center align-middle">Max</td>
                                    <td className="text-center align-middle">0</td>
                                    <td colSpan="10" className="text-center align-middle">
                                        30
                                    </td>
                                </tr>
                                <tr>
                                    <th className="align-middle" scope="row">
                                        Acid number <sup>(d)</sup>
                                    </th>
                                    <td className="text-center align-middle">mg KOH/g</td>
                                    <td className="text-center align-middle">ASTM D664</td>
                                    <td className="text-center align-middle">Max</td>
                                    <td colSpan="11" className="text-center align-middle">
                                        2.5
                                    </td>
                                </tr>
                                <tr>
                                    <th className="align-middle" scope="row">
                                        Used lubricating oil (ULO):
                                        <br />
                                        <br />
                                        Calcium and Zinc; or
                                        <br />
                                        Calcium and Phosphorus
                                    </th>
                                    <td className="text-center align-middle">mg/kg</td>
                                    <td className="text-center align-middle">
                                        <br />
                                        <br />
                                        IP 501/IP 470/
                                        <br />
                                        IP 500
                                    </td>
                                    <td className="text-center align-middle">Max</td>
                                    <td className="text-center align-middle" colSpan="11">
                                        The fuel shall be free from ULO. A fuel shall be considered to contain ULO when either one of
                                        the following conditions is met:
                                        <br />
                                        <br />
                                        calcium > 30 and zinc > 15; or
                                        <br />
                                        calcium > 30 and phosphorus > 15
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Hydrogen sulphide</th>
                                    <td className="text-center">mg/kg</td>
                                    <td className="text-center">IP 570</td>
                                    <td className="text-center">Max</td>
                                    <td colSpan="11" className="text-center">
                                        2.0
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div>
                        <sup>(a)</sup> This category is based on a previously defined distillate DMC category that was described in ISO
                        8217:2005.
                    </div>
                    <div>
                        <sup>(b)</sup> 1 mm2/s = 1cSt.
                    </div>
                    <div>
                        <sup>(c)</sup> The purchaser shall define the maximum sulfur content in accordance with relevant statutory
                        limitations. See 0.3 and Annex C.
                    </div>
                    <div>
                        <sup>(d)</sup> See Annex H.
                    </div>
                    <div>
                        <sup>(e)</sup> Purchasers shall ensure that this pour point is suitable for the equipment on board, especially
                        if the ship operates in cold climates.
                    </div>
                    <p className="mt-3">
                        More information on the International Organization for Standardization website:{" "}
                        <a href="https://www.iso.org/standard/59479.html" target="_blank" rel="noreferrer">
                            ISO 8217:2012
                        </a>
                        .
                    </p>
                </div>
            </div>
        </>
    )
}

export default Iso2012
